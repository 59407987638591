export default {
    methods: {
        scrollToClass(className) {
            const items = document.getElementsByClassName(className);
            const visible = [...items].filter((el) => {
                return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
            });

            if (visible.length > 0) {
                let offset = 0;
                
                if (items[0].offsetParent) {
                    offset = items[0].offsetParent.offsetTop;
                }

                if (items[0].parentElement && items[0].parentElement.offsetTop > offset) {
                    offset = items[0].parentElement.offsetTop;
                }

                if (items[0].offsetTop && items[0].offsetTop  > offset) {
                    offset = items[0].offsetTop;
                }
                
                window.scrollTo({
                    top: offset - 200,
                    behavior: 'smooth'
                });
            }
        }
    }
}